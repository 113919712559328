@import './styles.scss';

.competence {
    margin: 20px;
    background-color: $secondcolor;
    color: $text-color;
    border: 2px solid rgb(128, 128, 128);
    font-size: 15px;
    width: 100%;
    border-radius: 20px;
    .containe {
        width: 90%;
        margin: auto;
    }
    h2 {
        margin: 0;
    }
    .progressbar {
        background-color: aquamarine;
        display: flex;
        height: 2px;
        margin: 15px 0px 15px 0px;
        align-items: center;
        justify-content: flex-end;
    }
    .titlePourcentage {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
    }
    h3 {
        margin: 0;
        display: flex;
        justify-content: flex-end;
    }
}

.divCompetences {
    .skillsContainer {    
        align-items: center;
        display: flex;
        flex-direction: column;}
    button {
        background-color: none;
    }
}