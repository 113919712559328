@import './styles.scss';

.project {
    border: 2px solid $border-color;
    border-radius: 10px;
    background-color: $secondcolor;
    width: 600px;
    height: 300px;
    .info {
        height: calc(50% - 10px);
        margin-left: 10px;
        padding-top: 2%;
    }
    h3{
        margin: none;
    }
    h2 {
        margin: 0;
        margin-bottom: 0;
    }
    p {
        margin-top:  5px;
        margin-bottom: 0;
    }
    img {
        width: 100%;
        height: 50%;
        object-fit: cover;
        border-radius: 0px 0px 10px 10px;
    }
    .linkCard {
        color: $thirdcolor;
        margin-top: 10px;
    }
}
@media (max-width: 768px) {
    .project{
        height: 200px;
        width: 90%;
        display: flex;
        flex-direction: column;
            p {
                font-size: 16px;
            }
        .info {
            height: 100%;
        }
    }
    
}