// ecrit une variable scss avec la couleur #F2ECE9
// et qui est utilisée pour le background de l'element <body>
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Playfair +Display:ital,wght@0,400..900;1,400..900&display=swap');
$primari-color: #191919;
//$secondcolor: #232D3F;
$secondcolor: #304160;
$thirdcolor: #A78295;
$text-color:#F5E8C7;
$border-color:rgb(128, 128, 128);
h1 {
    font-size: 50px;
    
}
h1,h2 {
    margin-left: 10px;
    font-family: "Playfair Display", serif;
}
h3, h4 {
    font-family: "Playfair Display", serif;
}
h2 {
    font-size: 27px;
}
body {
    background-color: $primari-color;
    color: $text-color;
    margin: 0;
    font-family: "Lato", sans-serif;
}
li{
    list-style: none;
}
a {
    text-decoration: none;
    color: $text-color;
}
button {
    color: $text-color;
    background-color: $secondcolor;
    margin-left: 5px;
    height: 20px;
    border-radius: 10px;
    border: 2px solid rgb(128, 128, 128);
}

.cardsProject {
    .cardsProject{
        display: flex;
    }
    .projects {
        display: flex;
        border-bottom: $border-color 1px solid;
        border-top: $border-color 1px solid;
    }
    .project {
        margin: 10px;
    }
}
.competenceAndAbout{    
    display: flex;
    border-bottom: $border-color 1px solid;
    //height: calc(100% - 10px);
    padding-bottom: 20px;
    
    .about {
        width: 50%;
        p {  
            font-size: 22px;
            width: 80%;
            margin: auto;
        }
    }
    .divCompetences {
        width: 50%;
        .competence {
            width: 80%;
        }
        
    }

}
.loisireAndParcours {
    display: flex;
    flex-direction: column;
    border-bottom: $border-color 1px solid;
    //height: calc(100% - 10px);
    padding-bottom: 20px;
    .hobbies {
        height: 100%;
    }
}
.divCompetences {
    button {
        background: none;
        border: none;
        color: $text-color;
        text-decoration:underline;
        margin: auto;
    }
}
.parcours {
    h3{ 
        margin-left: 10px;
    }
}
@media (max-width: 768px) {  
    .cardsProject {
        .cardsProject{
            display: flex;
        }
        .projects {
            display: flex;
            border-bottom: $border-color 1px solid;
            border-top: $border-color 1px solid;
            flex-direction: column;
        }
        .project {
            margin: 10px;
        }
        .swiper-slide-active {
            width: 100%;
        }
    }
    .competenceAndAbout{    
        display: block;
        border-bottom: $border-color 1px solid;
        //height: calc(100% - 10px);
        padding-bottom: 20px;
        
        .about {
            width: 100%;
            p {
                width: 80%;
                margin: auto;
            }
        }
        .divCompetences {
            width: 100%;
            .competence {
                width: 80%;
            }
            
        }
    
    }
  
  }