@import './styles.scss';
.tag {
    background-color: $secondcolor;
    color: $text-color;
    padding: 3px;
    height: 100%;
}
.hobbies { 
    .tags-container{
        margin: auto;
        width: calc(90% - 50px);
        display: grid;
        grid-template-columns: 25% 25% 25% 25%;
	    grid-column-gap: 30px;
	    grid-row-gap: 20px;
        margin-bottom: 10px;
        justify-content: space-evenly;
        justify-items: center;
        
        .tag {
            width: 100%;
            display: flex;
            height: 40px;
            align-items: center;
            justify-content: center;
            text-align: center;
            border-radius: 20px;
            border: 2px solid rgb(128, 128, 128);
        }
    }
    &::after {
    margin: auto;
    display: flex;
    content: "";
    width: 90%;
    height: 2px;
    background: #ffffff;
      }
}

@media (max-width: 768px) {
    .hobbies { 
    .tags-container {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-column-gap: 20px;
    }
    .tag {
        border-radius: 10px;
    }
}
}