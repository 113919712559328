.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
   .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  } 

  /* .swiper-container {
    width: 480px;
  } */
  
  @media screen and (min-width: 640px) {
    .swiper-container {
      width: 640px;
    }
  }
  
  /* @media screen and (min-width: 768px) {
    .swiper-container {
      width: 768px;
    }
  } */