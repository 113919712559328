@import './styles.scss';
.parcours {
    h4, p {
        margin: 5px;
    }
    li {
        margin-top: 10px;
    }
    h3 {
        font-size: 22px;
    }
    .practice {
        .title {
            height: 30px;
            display: flex;
            align-items: center;
            font-size: 20px;
        }
        li {
            background-color: $secondcolor;
            border-bottom: $border-color solid 2px;
            border-top: $border-color solid 2px;
            padding-inline-start: 40px;
            margin-bottom: 20px;
        }
        ul {
            padding-inline-start: 0;
            margin-bottom: 25px;
        }
        p{
            font-size: 17px;
        }
    }
    //.qualification{}
}